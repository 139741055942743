import styled from "styled-components";

const Textarea = styled.textarea`
  display: block;
  width: 100%;
  overflow: hidden;
  resize: both;
  min-height: 40px;
  line-height: 20px;
`;

const allStyled = {
  Textarea,
};

export default allStyled;
