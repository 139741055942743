import styled from "styled-components";
import typhography from "../../../theme/mixins/typhography";
import MEDIA from "../../../theme/helpers/mediaTemplates";
export const Wrapper = styled.div`
  padding: 3px 4px;
  background: ${(props) =>
    props.primary
      ? `linear-gradient(
    135deg,
    ${props.theme.halfUp},
    ${props.theme.lighterOne}
  )`
      : `linear-gradient(
    135deg,
    ${props.theme.default_halfUp},
    ${props.theme.default_lighterOne}
  )`};
  color: ${(props) => props.theme.default_darkerTwo};
  border-radius: 4px;
  display: inline-block;
  ${typhography.overlines}
  font-size:0.8rem;
  letter-spacing: 0.2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  display: flex;
  width: fit-content;
  align-items: center;

  /* &:hover {
    background: ${(props) =>
    props.primary
      ? `linear-gradient(
        135deg,
        ${props.theme.darkerOne},
        ${props.theme.primary}
      )`
      : `linear-gradient(
        135deg,
        ${props.theme.default_darkerOne},
        ${props.theme.default_primary}
      )`};
  } */

  ${MEDIA.PHONE`
    font-size: 0.7rem;
  `}
`;

export const Tag = styled.div`
  font-size: 0.7em;
  text-transform: uppercase;
  margin-left: 8px;
  padding: 0.5px 4px;
  border: ${(props) =>
    props.primary
      ? `1px solid ${props.theme.primary}
  `
      : `1px solid ${props.theme.default_darkerOne}
  `};
  ${MEDIA.PHONE`
    margin-left:4px;  
    padding: 0.5px 2px;

  `}
`;
