// import React from 'react'
import { theme } from "../../theme/variables";
import styled from "styled-components";
import MEDIA from "../../theme/helpers/mediaTemplates";

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${MEDIA.MIN_DESKTOP`
		flex-direction:row;
	`}
`;
const Main = styled.article`
  width: 100%;
  ${MEDIA.MIN_DESKTOP`
		width:70%;
	`}
`;

const Aside = styled.aside`
  width: 100%;
  padding-left: 0px;
  ${MEDIA.MIN_DESKTOP`
		width:30%;
		padding-left:32px;
		display:block;
	`}
`;

const Content = styled.div`
  max-width: ${theme.contentWideMaxWidth};
  margin: auto;
  /* position: relative; */
  display: flex;
  flex-direction: column;
  padding: ${theme.halfGutter};
`;

// function Layout() {
//     return (
//         <div>

//         </div>
//     )
// }
const allStyled = {
  BodyContainer,
  Main,
  Aside,
  Content,
};
export default allStyled;
