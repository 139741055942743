import React from "react";
import { Wrapper, Tag } from "./Label.styled";

const Label = ({ content, primary, tag }) => {
  if (!content) return null;
  const classNames = `c-label ${primary && "c-label--primary"}`;
  return (
    <Wrapper className={classNames} primary={primary}>
      {content}
      {tag && <Tag>{tag}</Tag>}
    </Wrapper>
  );
};
export default Label;
