import styled from "styled-components";

const Primary = styled.span`
  background: ${(props) => props.theme.halfUp};
  font-size: 0.6rem;
  font-weight: 700;
  color: white;
  letter-spacing: 1px;
  border-radius: 2px;
  box-shadow: 0px 2px 3px ${(props) => props.theme.default_darkerOne},
    -1px -1px 1px ${(props) => props.theme.default_lighterOne};
  padding: 3px 6px 4px 6px;
  border-radius: 2px;
  margin-right: 12px;
  word-break: keep-all;
  display: inline-block;
  :last-child {
    margin-right: 0px;
  }
`;

const Default = styled(Primary)`
  background-color: ${(props) => props.theme.default_lighterTwo};
  box-shadow: 0px 2px 3px ${(props) => props.theme.darkerOne},
    -1px -1px 1px ${(props) => props.theme.lighterOne};
  color: ${(props) => props.theme.darkerTwo}!important;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  color: white;
  span {
    color: ${(props) => props.theme.darkerTwo};
  }
  margin-top: 14px;
  padding-top: 10px;
  border-top: 2px solid ${(props) => props.theme.halfUp};
  font-size: 0.8em;
`;

const allStyled = {
  Primary,
  Default,
  Container,
};

export default allStyled;
