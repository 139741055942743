// import { theme } from "../../theme/variables"
import styled from "styled-components";
// import MEDIA from "../../theme/helpers/mediaTemplates"
import mixins from "../../theme/mixins";

export const BannerContainer = styled.div`
  background: #e0e0e0;
  padding: 32px;
  position: sticky;
  top: 150px;
  border-radius: 32px;
  ${mixins.colored.bordered.default.primary}// ${mixins.shadowed.default.small}
`;
