import React from "react";
import SendInBlueForm from "../Forms/SendInBlueForm";
import * as Styled from "./styled";

const Banner = () => {
  return (
    <Styled.BannerContainer
      className={"banner-sidebar-container subscribe-form"}
    >
      <h3>Are you serious about your Dutch?</h3>
      <p>
        We are The Dutch Online Academy and are committed to keep helping over
        11.000 Dutch learners every week!
      </p>
      <p>Are you one of them?</p>
      <SendInBlueForm subscribeTitle={"Join our weekly lesson"} />
    </Styled.BannerContainer>
  );
};
export default Banner;
