import styled from "styled-components";
import mixins from "../../theme/mixins";
// import MEDIA from "../../theme/helpers/mediaTemplates";

export const Wrapper = styled.div`
  /* border: 1px solid lightgray; */
  /* padding: 10px; */
`;
export const Header = styled.header`
  display: flex;
  border-bottom: 1px solid gray;
`;
export const Body = styled.div``;
export const Date = styled.span``;

export const FormBox = styled.div`
  border-radius: 20px;
  ${mixins.shadowed.inner.transparent};
  max-width: 360px;
  padding: 2rem 1rem;
  display: flex;
  margin: auto;
  flex-direction: column;
  label,
  input,
  textarea {
    display: block;
    width: 100%;
  }
  input,
  textarea {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    border: 1px solid gray;
  }
  button {
    margin: 1rem 0 1rem auto;
    width: 100%;
    &:disabled {
      background: ${(props) => props.theme.lighterTwo} !important;
      border-color: ${(props) => props.theme.lighterOne} !important;
    }
  }
`;
