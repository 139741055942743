import styled from "styled-components";
// import { theme } from "../../theme/variables";
import MEDIA from "../../theme/helpers/mediaTemplates";

export const Wrapper = styled.div`
  /* border-top: 1px solid lightgray; */
  padding: 10px;
  padding-top: 16px;
  /* padding-left: ${(props) => (props.isResponse ? "20px" : "10px")}; */
  ${(props) =>
    props.isResponse
      ? `
  padding-left: 10px;
  margin-left:0px;
  border-left:2px solid ${props.theme.default_lighterOne};`
      : `
  padding-left:0px;`};
`;
export const Header = styled.header`
  display: flex;
  align-items: center;
  /* border-bottom: 1px solid gray; */
  padding-bottom: 10px;
`;
export const Body = styled.div``;
export const Date = styled.span`
  padding-left: 8px;
  font-size: 0.8rem;
  ${MEDIA.PHONE`
  font-size: 0.7rem;
    
  `}
`;
