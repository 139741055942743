import React from "react";
import Label from "../UI/Label/Label";
import { Header, Wrapper, Body, Date } from "./CommentBox.styled";

const CommentBox = ({ comment, author, date, isResponse, isByAutor }) => {
  return (
    <Wrapper
      className={`c-comment-box 
        ${isResponse && "c-comment-box--response"} 
        ${isByAutor && "c-comment-box--by-author"}`}
      isResponse={isResponse}
    >
      <Header>
        <Label
          content={author}
          primary={isByAutor}
          tag={isByAutor && "author"}
        />
        <Date>{date}</Date>
      </Header>
      <Body>{comment}</Body>
    </Wrapper>
  );
};

export default CommentBox;
