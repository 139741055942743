import React, { useState, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Wrapper, FormBox } from "./CommentsBlock.styled";
import CommentBox from "../CommentBox/CommentBox";
import Panel from "../UI/Panel/Panel";
import LoginButton from "../LoginButton";
import { postData } from "../../utils/helpers";
import ReCAPTCHA from "react-google-recaptcha";
import { Button } from "../UI";
import { pushEvent } from "../../utils/tagManager";

const CommentWrapper = (props) => {
  const data = useStaticQuery(graphql`
    query CommentsQuery {
      BackendGraph {
        comments {
          author
          comment
          dateAdded
          id
          isByAutor
          isResponse
          postId
          status
        }
      }
    }
  `);
  return (
    <CommentsBlock
      comments={data?.BackendGraph?.comments?.filter(
        (comment) => comment.postId === props.elementId,
      )}
      {...props}
    />
  );
};

const CommentForm = ({ handleSubmit, elementId }) => {
  const commentCaptchaRef = useRef(null);

  const [comment, set_comment] = useState("");
  const [captchaFilled, set_captchaFilled] = useState(false);

  const [name, set_name] = useState("");

  return (
    <FormBox>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const captcha = commentCaptchaRef.current.getValue();

          handleSubmit({
            comment,
            name,
            captcha,
          });
        }}
      >
        <div className="field">
          <label htmlFor="name">Name to display:</label>
          <input name="name" onChange={(e) => set_name(e.target.value)} />
        </div>
        <div className="field">
          <label htmlFor="comment">Comment:</label>
          <textarea
            name="comment"
            rows="5"
            onChange={(e) => set_comment(e.target.value)}
          />
        </div>
        <ReCAPTCHA
          ref={commentCaptchaRef}
          sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA}
          onChange={() => {
            pushEvent({
              event: "comment-action",
              action: "captcha-filled",
              location: `bottom-input`,
            });
            set_captchaFilled(true);
          }}
        />
        <Button.PrimaryDefault type="submit" disabled={!captchaFilled}>
          Submit your comment
        </Button.PrimaryDefault>
      </form>
    </FormBox>
  );
};

const CommentsBlock = ({ comments, user, getToken, elementId }) => {
  const [loading, set_loading] = useState(false);
  const [userFeedback, set_userFeedback] = useState();
  const handleSubmit = async (formValue) => {
    set_loading(true);
    try {
      const token = await getToken();
      // const response = await postData({
      await postData({
        url: `${process.env.GATSBY_BACKEND_URL}/comments/post-comment`,
        data: {
          ...formValue,
          currentUrl: window.location.href,
          postId: elementId,
          pocketDeviceId: window.pocket?.pocketClient ?? null,
        },
        token,
      });
      set_userFeedback(
        "Thank you for your comment, we are reviewing it and will be vissible soon.",
      );
      set_loading(false);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("error", e);
    }
  };

  return (
    <Panel title={"comments"} anchor="comments">
      {userFeedback ? (
        <h2>{userFeedback}</h2>
      ) : loading ? (
        <h2>Loading</h2>
      ) : user?.authenticated ? (
        <CommentForm handleSubmit={handleSubmit} />
      ) : (
        <div>
          <br />
          <div className="header">Login to leave a comment</div>
          <br />
          <LoginButton location="comments-block" />
        </div>
      )}
      <Wrapper className={`c-comments-block`}>
        {comments ? (
          comments.map((c, i) => <CommentBox key={i} {...c} />)
        ) : (
          <h3>Leave the first comment</h3>
        )}
      </Wrapper>
    </Panel>
  );
};

// export default CommentsBlock;

export default CommentWrapper;
